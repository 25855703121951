import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'

export type buildUrlMessageData = {
	identifier: string
	data: Record<string, string>
	pathPrefix: string
	options?: Record<string, string>
}

export type HandlerResponse = { url: string }

export const BuildUrlHandler = withDependencies(
	[],
	(): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				buildUrl(compId, msgData: buildUrlMessageData): HandlerResponse {
					const { identifier = '', data = { slug: '' }, pathPrefix = '', options = {} } = msgData
					console.log(
						`TPA Handlers: buildUrl invoked for ${identifier} with data: ${data}. Prefix is ${pathPrefix} and options are ${options}`
					)
					return { url: [pathPrefix, data?.slug].join('/') }
				},
			}
		},
	})
)
