import { withDependencies } from '@wix/thunderbolt-ioc'
import { TpaHandlerProvider } from '@wix/thunderbolt-symbols'

export type MessageData = { url: string; pathPrefix: string }
export type HandlerResponse = { urlSegments: Record<string, string> }

export const GetUrlSegmentsHandler = withDependencies(
	[],
	(): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				getUrlSegments(compId, msgData: MessageData): HandlerResponse {
					const { url = '', pathPrefix = '' } = msgData
					const validBaseUrl = 'https://baseUrl'
					const urlPath = new URL(url, validBaseUrl).pathname
					const slashesRegEx = /\//g
					const slug = urlPath.replace(pathPrefix, '').replace(slashesRegEx, '')
					return { urlSegments: { slug } }
				},
			}
		},
	})
)
