import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	PlatformWorkerPromise,
	PlatformWorkerPromiseSym,
	SdkHandlersProvider,
	PublicAPIs,
	TpaHandlerProvider,
	FeatureStateSymbol,
	IPageWillUnmountHandler,
} from '@wix/thunderbolt-symbols'
import { loadScriptTag, loadScriptWithRequireJS, scriptUrls } from '@wix/thunderbolt-commons'
import { IFeatureState } from 'thunderbolt-feature-state'
import { name } from './symbols'

async function loadPmRpc() {
	if (window.pmrpc) {
		return window.pmrpc
	}
	if (window.define && window.define.amd) {
		return loadScriptWithRequireJS(scriptUrls.PM_RPC)
	}
	await loadScriptTag(scriptUrls.PM_RPC)
	return window.pmrpc
}

type TpaState = {
	publicApiTPAHandlerState: {
		alreadyInvoked: boolean
		resolvePublicApiGetter: Function
		waitForAppsToRegister: Promise<Function>
	}
}

const publicApiTPAHandler = (
	featureState: IFeatureState<TpaState>,
	platformWorkerPromiseObj: {
		platformWorkerPromise: PlatformWorkerPromise
	}
): TpaHandlerProvider & SdkHandlersProvider<any> & IPageWillUnmountHandler => {
	if (!featureState.get()?.publicApiTPAHandlerState) {
		const alreadyInvoked = false
		let resolvePublicApiGetter: Function
		const waitForAppsToRegister: Promise<Function> = new Promise((res) => {
			resolvePublicApiGetter = res
		})
		featureState.update(() => ({
			...featureState.get(),
			publicApiTPAHandlerState: {
				alreadyInvoked,
				resolvePublicApiGetter,
				waitForAppsToRegister,
			},
		}))
	}
	return {
		pageWillUnmount() {
			featureState.update(() => ({
				...featureState.get(),
				// @ts-ignore
				publicApiTPAHandlerState: null,
			}))
		},
		getTpaHandlers() {
			return {
				waitForWixCodeWorkerToBeReady: async () => {
					if (featureState.get().publicApiTPAHandlerState.alreadyInvoked) {
						return {}
					}

					const [pmRpc, worker, getPublicApiNames] = (await Promise.all([
						loadPmRpc(),
						platformWorkerPromiseObj.platformWorkerPromise,
						featureState.get().publicApiTPAHandlerState.waitForAppsToRegister,
					])) as Array<any>
					const appsPublicApisNames = await getPublicApiNames()
					await Promise.all(
						appsPublicApisNames.map((appName: string) =>
							pmRpc.api.request(appName, { target: worker }).then((publicAPI: PublicAPIs) => {
								pmRpc.api.set(appName, publicAPI)
							})
						)
					)
					featureState.update(() => ({
						...featureState.get(),
						publicApiTPAHandlerState: {
							...featureState.get().publicApiTPAHandlerState,
							alreadyInvoked: true,
						},
					}))

					return {}
				},
			}
		},
		getSdkHandlers: () => ({
			// TODO Or Granit 06/07/2020: GilEck promised that he'll refactor this ugly pattern
			registerPublicApiGetter: (appsPublicApisGetter: Function) => {
				featureState.get().publicApiTPAHandlerState.resolvePublicApiGetter(appsPublicApisGetter)
			},
		}),
	}
}

export const PublicApiTPAHandler = withDependencies(
	[named(FeatureStateSymbol, name), optional(PlatformWorkerPromiseSym)],
	publicApiTPAHandler
)
